@media (min-width: 768px) {
    .mobile-menu, .mobile-menu-toggle {
        display: none !important;
    }
}

.mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    top: 5em;
    left: 0;
    background-color: #F9F9F9;
    padding-top: 5em;
    text-align: right;
}

.mobile-menu-toggle:hover {
    cursor: pointer;
}
