.cookie-banner {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3.125em;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 4px;
    z-index: 100;
    padding: 1em;
    max-width: 50em;
}

@media screen and (max-width: 991px) {
    .cookie-banner {
        width: 70%;
    }
}

@media screen and (max-width: 767px) {
    .cookie-banner {
        width: 90%;
    }
}
