html, body, #root, .app {
    height: 100%;
    max-width: 1320px;
    margin: 0px auto;
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 3.125em;
}

.app-content {
    flex: 1 0 auto;
    margin: 3.125em 0;
}

/* Prevent body from scrolling behind the mobile menu. Overscroll behavior
   does not work because the mobile menu has nothing to scroll. See e.g.
   https://ishadeed.com/article/prevent-scroll-chaining-overscroll-behavior */
body.mobile-menu-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
}
