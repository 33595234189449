.sign-out-item {
    text-transform: lowercase !important;
}

.sign-out-item::first-letter {
    text-transform: uppercase !important;
}

@media only screen and (max-width: 767px) {
    .app-sidebar {
        display: none !important;
    }
}
