.mobile-menu-item {
    display: block;
    text-align: center;
    height: 2.5em;
    line-height: 2.5em;
    font-size: 1.5em;
    text-transform: capitalize;
}

.mobile-menu-item.active {
    text-decoration: underline;
}

.mobile-menu-item:hover {
    cursor: pointer;
}
