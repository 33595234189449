.fine-print {
    text-transform: uppercase !important;
    color: gray !important;
    font-size: 0.625em !important;
}

/* Show a horizontal scroll bar for tab panes on mobile.
   This applies to the tab component on e.g. licenses and admin pages. */
@media only screen and (max-width: 767px) {
    .ui.attached.tabular.menu {
        overflow-x: auto;
        overflow-y: hidden;
    }
}
